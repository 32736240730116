"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.postMultiform = exports.post = exports.get = exports.useUserId = exports.UserIdContext = void 0;
const react_1 = require("react");
// context for user ID to consume it down the tree
exports.UserIdContext = (0, react_1.createContext)("");
const useUserId = () => (0, react_1.useContext)(exports.UserIdContext);
exports.useUserId = useUserId;
// HTTP wrappers provided by web.lab stuff
const formatParams = (params) => {
    return Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join("&");
};
const convertToJSON = (res) => {
    if (!res.ok) {
        throw `API request failed with response status ${res.status} and text: ${res.statusText}`;
    }
    return res
        .clone() // clone so that the original is still readable for debugging
        .json() // start converting to JSON object
        .catch((error) => {
        // throw an error containing the text that couldn't be converted to JSON
        return res.text().then((text) => {
            throw `API request's result could not be converted to a JSON object: \n${text}`;
        });
    });
};
// Helper code to make a get request. Default parameter of empty JSON Object for params.
// Returns a Promise to a JSON Object.
const get = (endpoint, params = {}) => {
    const fullPath = endpoint + "?" + formatParams(params);
    return fetch(fullPath)
        .then(convertToJSON)
        .catch((error) => {
        // give a useful error message
        throw `GET request to ${fullPath} failed with error:\n${error}`;
    });
};
exports.get = get;
// Helper code to make a post request. Default parameter of empty JSON Object for params.
// Returns a Promise to a JSON Object.
const post = (endpoint, params = {}) => {
    return fetch(endpoint, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
    })
        .then(convertToJSON) // convert result to JSON object
        .catch((error) => {
        // give a useful error message
        throw `POST request to ${endpoint} failed with error:\n${error}`;
    });
};
exports.post = post;
// Similar helper but for multiform data
const postMultiform = (endpoint, formData) => {
    return fetch(endpoint, {
        method: "post",
        // Explicitly specifying the header causes an error.
        // According to https://chat.openai.com/share/78a35daa-0a77-4bbb-af9b-f8383f3bec73
        // the hader is automatically inferred by JS with the right "boundary" part of the header
        body: formData,
    }).then(convertToJSON);
};
exports.postMultiform = postMultiform;
