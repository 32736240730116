"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
// import { Link } from "react-router-dom";
const material_1 = require("@mui/material");
require("./Resources.css");
// Masterlist of resources for food insecurity at MIT, with links to each resource's website.
const Resources = () => {
    (0, react_1.useEffect)(() => {
        document.title = "Resources Page";
    }, []);
    return (react_1.default.createElement("div", { className: "Resources" },
        react_1.default.createElement("div", { className: "u-hideOverflow Resources-container" },
            react_1.default.createElement("div", { className: "u-flexColumn Resources-textContainer" },
                react_1.default.createElement("h1", null, "Resources"),
                react_1.default.createElement("div", { className: "u-flex u-flex-justifyCenter u-flex-alignCenter" },
                    react_1.default.createElement("p", null,
                        "Here are some resources for food insecurity at MIT. If you know of any other resources that should be added to this list, please let us know at",
                        " ",
                        react_1.default.createElement("strong", null,
                            react_1.default.createElement(material_1.Link, { href: "mailto:unsubscribe-admin@mit.edu" }, "Unsubscribe-admin@mit.edu")),
                        ".")),
                react_1.default.createElement("h2", null, "Websites:"),
                react_1.default.createElement("ul", null,
                    react_1.default.createElement("li", null,
                        react_1.default.createElement(material_1.Link, { href: "https://studentlife.mit.edu/s3/money-food-resources/food-resources" }, "MIT Food Resources via S^3")),
                    react_1.default.createElement("li", null,
                        react_1.default.createElement(material_1.Link, { href: "https://doingwell.mit.edu/foodandfinancial/" }, "Food and Financial Resources via MIT Office of Student Wellbeing")),
                    react_1.default.createElement("li", null,
                        "Via",
                        " ",
                        react_1.default.createElement(material_1.Link, { href: "https://oge.mit.edu/student-finances/financial-assistance-and-grants/food-insecurity/" }, "Office of Graduate Education"),
                        ":",
                        react_1.default.createElement("ul", { className: "a" },
                            react_1.default.createElement("li", null,
                                react_1.default.createElement(material_1.Link, { href: "https://www.google.com/maps/d/u/0/viewer?ll=42.360563087188545%2C-71.09648094149668&z=16&mid=1i0YNzpQzO2Zou13n9ZahvjL1L7feLFA5" }, "MIT Food & Grocery Map"),
                                ": This interactive map showcases area grocery stores, on-campus dining locations, and local favorite food spots."),
                            react_1.default.createElement("li", null,
                                react_1.default.createElement(material_1.Link, { href: "https://docs.google.com/presentation/d/1rQ0hjf1Zs8_6yBuSKKYdFXp8yx1KUFwRhR53gYRg2QQ/edit#slide=id.p" }, "Cooking with Antoinette"),
                                ": This cookbook was created by the International Student Office and has over 50 easy-to-follow recipes."),
                            react_1.default.createElement("li", null,
                                react_1.default.createElement(material_1.Link, { href: "http://web.mit.edu/facilities/transportation/shuttles/grocery.html" }, "Grocery Shuttles"),
                                ": See when MIT's grocery shuttle runs to Costco, Trader Joe's, and Whole Foods."))))))));
};
exports.default = Resources;
