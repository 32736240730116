"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
// import { CredentialResponse, GoogleLogin, googleLogout } from "@react-oauth/google";
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const material_1 = require("@mui/material");
const utilities_1 = require("../../utilities");
const DarkToggle_1 = require("./DarkToggle");
require("./NavBar.css");
/**
 * The navigation bar at the bottom of all pages. Takes no props.
 */
const NavBar = (props) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const [user, setUser] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        (0, utilities_1.get)(`/api/whoami`).then((userObj) => setUser(userObj));
    }, []);
    const renderAvatar = (user) => {
        // Handle Google avatars
        if (user.picture) {
            return react_1.default.createElement(material_1.Avatar, { alt: "Avatar", src: user.picture, sx: { width: 34, height: 34 } });
        }
        else {
            // Default avatar or placeholder if login type is unknown
            return (
            // <Avatar sx={{ bgcolor: "secondary.main", width: 34, height: 34 }} aria-label="recipe">
            react_1.default.createElement(material_1.Avatar, { className: "grad", sx: { color: "secondary.contrastText", bgcolor: "secondary.main", width: 34, height: 34 }, "aria-label": "recipe" }, user.name.at(0)));
        }
    };
    return (
    // TODO: for accessibility etc we might want a link instead of a button but I can't figure out how to do that
    react_1.default.createElement(material_1.AppBar, { className: "NavBar-container", position: "sticky" },
        react_1.default.createElement(material_1.Toolbar, null,
            react_1.default.createElement(material_1.Box, null,
                react_1.default.createElement("img", { className: "Navbar-logo", src: props.logo, alt: "Unsubscribe logo", height: 34.5 })),
            "\u00A0\u00A0",
            react_1.default.createElement(material_1.Typography, { className: "NavBar-title", fontSize: 34, variant: "h6", color: "secondary", 
                // fontFamily={"Libre Barcode 128 Text"}
                sx: {
                    paddingRight: 2.5,
                    display: {
                        xs: "none",
                        sm: "block",
                    },
                } },
                react_1.default.createElement(material_1.Box, { className: "NavBar-title" }, "Unsubscribe")),
            react_1.default.createElement(material_1.Button, { sx: { marginRight: 1, fontWeight: 550 }, onClick: () => navigate("/food/latest"), color: "inherit" }, "Food"),
            react_1.default.createElement(material_1.Button, { sx: { marginRight: 1, fontWeight: 550 }, onClick: () => navigate("/food/calendar"), color: "inherit" }, "Calendar"),
            react_1.default.createElement(material_1.Button, { sx: { marginRight: 1, fontWeight: 550 }, onClick: () => navigate("/yourposts"), color: "inherit" }, "Your Posts"),
            react_1.default.createElement(material_1.Button, { sx: { marginRight: 1, fontWeight: 550 }, onClick: () => navigate("/resources"), color: "inherit" }, "Resources @ MIT"),
            react_1.default.createElement(material_1.Button, { sx: { marginRight: 1, fontWeight: 550 }, onClick: () => navigate("/about"), color: "inherit" }, "About"),
            react_1.default.createElement(material_1.Box, { sx: { flexGrow: 1 } }),
            react_1.default.createElement(DarkToggle_1.MaterialUISwitch, { className: "u-pointer", checked: props.themeMode === "dark", onChange: () => props.setThemeMode(props.themeMode === "light" ? "dark" : "light") }),
            user && (react_1.default.createElement(material_1.Button, { className: "u-pointer", onClick: () => {
                    // googleLogout();
                    props.handleLogout();
                }, color: "secondary", sx: { fontWeight: 550 } },
                "Logout \u00A0\u00A0 ",
                renderAvatar(user))))));
};
exports.default = NavBar;
